import * as React from 'react';
import { graphql, PageRendererProps } from 'gatsby';
import { Redirect } from '@reach/router';
import BaseLayout from '../components/layouts/BaseLayout';
import { ModuleRenderer } from '../modules/ModuleRenderer';
import { getPageAttributes } from '../modules/ModuleRenderer/utils';

interface IDefaultTemplate {
  data: Queries.DefaultTemplateQuery & IGreenHouseJob;
}

interface IGreenHouseJob {
  greenhouseJob: Queries.GreenhouseJob; 
}

const JobDetailTemplate: React.FC<IDefaultTemplate & PageRendererProps> = ({
  data: { page, greenhouseJob },
  ...props
}) => {
  if (!page) {
    console.warn('Unable to get data from wordpress pages query');
    return <Redirect to="/404" />;
  }

  const pageAttributes = getPageAttributes(page);

  return (
    <BaseLayout
      seo={{
        title: `${greenhouseJob.title}`,
        location: props.location.href,
        className: pageAttributes.className,
        metaDescription: pageAttributes.metaDescription,
        twitterSite: pageAttributes.twitterSite,
        twitterCard: pageAttributes.twitterCard,
        metaRobots: pageAttributes.metaRobots,
        metaImage: pageAttributes.metaImage,
      }}
    >
      <ModuleRenderer
        blocks={page.blocks}
        pageAttributes={{ title: `${greenhouseJob.title}`, ...pageAttributes }}
        greenhouseDetails={greenhouseJob}
      />
    </BaseLayout>
  );
};

export default JobDetailTemplate;

export const query = graphql`
  query JobDetailTemplate($id: String!, $job_post_id: Float) {
    page: wpPage (id: { eq: $id }) {
      ...JobDetailPage
    }
    greenhouseJob: greenhouseJob(gh_Id: {eq: $job_post_id}) {
      metadata {
        value
      }
      gh_Id
      title
      location {
        name
      }
      departments {
        name
      }
      content
    }
  }
`;
